import Feature from 'ol/Feature';
import { Modify, Select, Translate } from 'ol/interaction';
import { fromExtent } from 'ol/geom/Polygon';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';
import { MultiPoint } from 'ol/geom';
import { getCenter, getHeight, getWidth } from 'ol/extent';
import { never, platformModifierKeyOnly, pointerMove, primaryAction, shiftKeyOnly } from 'ol/events/condition';
import {
  changeMapCursor,
  getNumericalPopupInfo,
  isNumericalLayer,
  clearProperties
} from '../../../Utils/HelperFunctions';
import { TOOL_EVENT } from '../../Output/Toolbar/ToolController';
import { layerTracker } from '../MapInit';
import { GEOMETRY_TYPE_STRING, selectNonHighLightLayers } from '../../../Constants/Constant';
import { Observer } from '../../../Utils/Observer';
import { HIGHLIGHT_STYLE_NUMERICAL, highlightStyle, getDrawStyle } from '../MapBase';
import { getAreaStyleFunction } from '../../../Utils/olutils';

class ResizeRotate extends Observer {
  areaStyleCache: $TSFixMe;

  bpLotExtent: $TSFixMe;

  hover: $TSFixMe;

  invalidSpace: $TSFixMe;

  lastFeatures: $TSFixMe;

  lastGeometry: $TSFixMe;

  lotFeature: $TSFixMe;

  mapObj: $TSFixMe;

  modify: $TSFixMe;

  resizeRotate: $TSFixMe;

  select: $TSFixMe;

  translate: $TSFixMe;

  selectedFeature: $TSFixMe;

  constructor(mapObj: $TSFixMe) {
    super();
    this.mapObj = mapObj;
    this.hover = null;
    this.select = null;
    this.modify = null;
    this.resizeRotate = null;
    this.translate = null;
    this.lastFeatures = [];
    this.bpLotExtent = null;
    this.lotFeature = null;
    this.invalidSpace = false;
    this.lastGeometry = null;
    this.areaStyleCache = [];
    this.selectedFeature = null;
  }

  on() {
    this.off();

    this.mapObj.map.on('pointermove', this.changeCursor);

    if (this.mapObj.isBlueprintMap) {
      const bpSheetExtent = this.mapObj.baseLayer.get('bp_page_extent');
      this.bpLotExtent = new Feature(fromExtent(bpSheetExtent));
    }

    const style = new Style({
      geometry: feature => {
        const modifyGeometry = feature.get('modifyGeometry');
        return modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
      },
      fill: new Fill({
        color: '#4361ee4f'
      }),
      stroke: new Stroke({
        color: '#4361ee',
        width: 2
      }),
      image: new CircleStyle({
        radius: 7,
        fill: new Fill({
          color: '#4361ee',
          // @ts-expect-error TS(2345): Argument of type '{ color: string; width: number; ... Remove this comment to see the full error message
          width: 1
        }),
        stroke: new Stroke({
          color: '#ffffff'
        })
      })
    });

    this.select = new Select({
      // @ts-expect-error TS(2345): Argument of type '{ wrapX: boolean; filter: (featu... Remove this comment to see the full error message
      wrapX: false,
      filter: (feature, layer) => {
        // Do not select parcel layer  while editing features
        return (
          !selectNonHighLightLayers(layer?.get('name')) &&
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          feature.getGeometry().getType() !== GEOMETRY_TYPE_STRING.POINT
        );
      },

      style: feature => {
        const styles = [style];
        const modifyGeometry = feature.get('modifyGeometry');
        const geometry = modifyGeometry ? modifyGeometry.geometry : feature.getGeometry();
        const result = this.calculateCenter(geometry);
        const { center } = result;
        if (center) {
          const { coordinates } = result;
          if (coordinates) {
            const { minRadius } = result;
            const { sqDistances } = result;
            const rsq = minRadius * minRadius;
            const points = coordinates.filter((coordinate: $TSFixMe, index: $TSFixMe) => {
              return sqDistances[index] > rsq;
            });
            styles.push(
              new Style({
                geometry: new MultiPoint(points),
                image: new CircleStyle({
                  radius: 5,
                  fill: new Fill({
                    color: '#4361ee'
                  }),
                  stroke: new Stroke({
                    color: '#ffffff',
                    width: 1
                  })
                })
              })
            );
          }
        }
        return styles;
      }
    });
    this.select.on('select', (event: any) => {
      const selectedFeatures = event.target.getFeatures().getArray();
      if (selectedFeatures.length > 0) {
        this.selectedFeature = selectedFeatures[0];
      } else {
        this.selectedFeature = null; // Clear if no feature is selected
      }
    });
    this.mapObj.map.addInteraction(this.select);

    this.hover = new Select({
      condition: pointerMove,
      toggleCondition: () => false,
      filter: (feature, layer) =>
        (!selectNonHighLightLayers(layer?.get('name')) &&
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          feature.getGeometry().getType() !== GEOMETRY_TYPE_STRING.POINT &&
          !this.select.getFeatures().getArray().includes(feature)) ||
        isNumericalLayer(layer),
      style: feature => {
        const layer = this.mapObj.getLayerById(feature.get('layerId'));
        return layer && isNumericalLayer(layer)
          ? HIGHLIGHT_STYLE_NUMERICAL
          : // @ts-expect-error TS(2322): Type 'FeatureLike' is not assignable to type 'null... Remove this comment to see the full error message
            highlightStyle({ layer, feature });
      }
    });
    this.mapObj.map.addInteraction(this.hover);

    this.modify = new Modify({
      features: this.select.getFeatures(),
      condition: () => !this.invalidSpace,
      snapToPointer: true,
      style: getDrawStyle({
        applyMoreStyle: (feature: $TSFixMe, resolution: $TSFixMe, style: $TSFixMe) => {
          const areaStyle = getAreaStyleFunction({
            feature: this.selectedFeature,
            areaLabelStyleCache: this.areaStyleCache,
            options: { measureArea: true, measureLength: true },
            mapInfo: { isBlueprintMap: this.mapObj.isBlueprintMap }
          });
          if (areaStyle.length) {
            style = style.concat(areaStyle);
          }
          return style;
        }
      })
    });

    this.modify.on('modifystart', (e: $TSFixMe) => {
      const geom = e.features.getArray()[0]?.getGeometry();
      this.lastGeometry = geom.clone();

      if (!this.mapObj.isBlueprintMap) {
        const [extractLotFeature] = this.mapObj.getParcelFeaturesAtCoordinate(geom.getClosestPoint([1, 1]), true);
        this.lotFeature = extractLotFeature;
      }
    });

    this.modify.on('modifyend', this.handleModifyEnd);

    this.mapObj.map.addInteraction(this.modify);
    this.mapObj.map.on('contextmenu', this.removePointOnRightClick);

    const defaultStyle = new Modify({
      features: this.select.getFeatures()
    })
      .getOverlay()
      .getStyleFunction();

    this.resizeRotate = new Modify({
      features: this.select.getFeatures(),
      condition: event => primaryAction(event) && shiftKeyOnly(event) && !this.invalidSpace,
      deleteCondition: never,
      style: feature => {
        feature.get('features').forEach((modifyFeature: $TSFixMe) => {
          const modifyGeometry = modifyFeature.get('modifyGeometry');
          if (modifyGeometry) {
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            const point = feature.getGeometry().getCoordinates();
            let modifyPoint = modifyGeometry.point;
            if (!modifyPoint) {
              // save the initial geometry and vertex position
              modifyPoint = point;
              modifyGeometry.point = modifyPoint;
              modifyGeometry.geometry0 = modifyGeometry.geometry;
              // get anchor and minimum radius of vertices to be used
              const result = this.calculateCenter(modifyGeometry.geometry0);
              modifyGeometry.center = result.center;
              modifyGeometry.minRadius = result.minRadius;
            }

            const { center } = modifyGeometry;
            const { minRadius } = modifyGeometry;
            let dx;
            let dy;
            dx = modifyPoint[0] - center[0];
            dy = modifyPoint[1] - center[1];
            const initialRadius = Math.sqrt(dx * dx + dy * dy);
            if (initialRadius > minRadius) {
              const initialAngle = Math.atan2(dy, dx);
              dx = point[0] - center[0];
              dy = point[1] - center[1];
              const currentRadius = Math.sqrt(dx * dx + dy * dy);
              if (currentRadius > 0) {
                const currentAngle = Math.atan2(dy, dx);
                const geometry = modifyGeometry.geometry0.clone();
                geometry.scale(currentRadius / initialRadius, undefined, center);
                geometry.rotate(currentAngle - initialAngle, center);
                modifyGeometry.geometry = geometry;
              }
            }
          }
        });
        // @ts-expect-error TS(2722): Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        return defaultStyle(feature);
      }
    });

    this.resizeRotate.on('modifystart', (event: $TSFixMe) => {
      const geom = event.features.getArray()[0]?.getGeometry();
      this.lastGeometry = geom.clone();

      event.features.forEach((feature: $TSFixMe) => {
        feature.set('modifyGeometry', { geometry: feature.getGeometry().clone() }, true);
      });

      if (!this.mapObj.isBlueprintMap) {
        const [extractLotFeature] = this.mapObj.getParcelFeaturesAtCoordinate(geom.getClosestPoint([1, 1]), true);
        this.lotFeature = extractLotFeature;
      }
    });

    this.resizeRotate.on('modifyend', this.handleResizeRotateEnd);

    this.mapObj.map.addInteraction(this.resizeRotate);

    this.translate = new Translate({
      features: this.select.getFeatures(),
      condition: event => primaryAction(event) && platformModifierKeyOnly(event) && !this.invalidSpace
    });
    this.mapObj.map.addInteraction(this.translate);

    this.translate.on('translatestart', (e: $TSFixMe) => {
      this.lastFeatures = e.features.getArray().map((f: $TSFixMe) => f.clone());
    });

    this.translate.on('translateend', this.handleTranslateEnd);

    this.mapObj.map.on('singleclick', this.handleFeatureClick);
  }

  openNumericalPopup = (feature: $TSFixMe) => {
    const { id, layerId, pageX, pageY } = getNumericalPopupInfo(this.mapObj.map, feature);
    this.notifyObservers(TOOL_EVENT.NUMERICAL_FEATURE_POPUP_TOGGLE, { pageX, pageY, id, layerId, open: true });
  };

  handleFeatureClick = (e: $TSFixMe) => {
    this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
      if (!_layer) return null;
      if (isNumericalLayer(_layer)) {
        this.openNumericalPopup(_feature);
        return true;
      }
      return null;
    });
  };

  handleModifyEnd = (e: $TSFixMe) => {
    const features = e.features.getArray();
    const feature = features.length && features[0];
    if (feature) {
      this.lotFeature = null;

      if (this.invalidSpace) {
        feature.setGeometry(this.lastGeometry.clone());
        return;
      }
      const propertiesToCheck = ['edit_length', 'edit_perimeter', 'edit_area'];
      clearProperties(feature, propertiesToCheck);
      this.lastGeometry = feature.getGeometry().clone();

      const layerId = feature.get('layerId');

      // Push layer in tracker
      layerTracker.push(this.mapObj.getLayerName(layerId), layerId);

      this.notifyObservers(TOOL_EVENT.EDIT_LAYER);
    }
  };

  handleResizeRotateEnd = (event: $TSFixMe) => {
    event.features.forEach((feature: $TSFixMe) => {
      const modifyGeometry = feature.get('modifyGeometry');
      if (modifyGeometry) {
        // handling out_of_extent case
        const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
          geom: modifyGeometry.geometry,
          boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
        });

        this.lotFeature = null;

        if (is_out_of_extent) {
          feature.setGeometry(this.lastGeometry.clone());
        } else {
          this.lastGeometry = feature.getGeometry().clone();
          feature.setGeometry(modifyGeometry.geometry);
          const layerId = feature.get('layerId');
          // Push layer in tracker
          layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
          const propertiesToCheck = ['edit_length', 'edit_perimeter', 'edit_area'];
          clearProperties(feature, propertiesToCheck);
        }
        feature.unset('modifyGeometry', true);
      }
    });
    if (layerTracker.getArray().length) {
      this.notifyObservers(TOOL_EVENT.EDIT_LAYER);
    }
  };

  removePointOnRightClick = (e: $TSFixMe) => {
    e.preventDefault();
    return this.modify.removePoint();
  };

  calculateCenter(geometry: $TSFixMe) {
    let center: $TSFixMe;
    let coordinates;
    let minRadius;
    const type = geometry.getType();
    if (type === 'Polygon') {
      let x = 0;
      let y = 0;
      let i = 0;
      coordinates = geometry.getCoordinates()[0].slice(1);
      coordinates.forEach((coordinate: $TSFixMe) => {
        x += coordinate[0];
        y += coordinate[1];
        i++;
      });
      center = [x / i, y / i];
    } else if (type === 'LineString') {
      center = geometry.getCoordinateAt(0.5);
      coordinates = geometry.getCoordinates();
    } else {
      center = getCenter(geometry.getExtent());
    }
    let sqDistances;
    if (coordinates) {
      sqDistances = coordinates.map((coordinate: $TSFixMe) => {
        const dx = coordinate[0] - center[0];
        const dy = coordinate[1] - center[1];
        return dx * dx + dy * dy;
      });
      // @ts-expect-error TS(2345): Argument of type 'Math' is not assignable to param... Remove this comment to see the full error message
      minRadius = Math.sqrt(Math.max(Math, sqDistances)) / 3;
    } else {
      minRadius = Math.max(getWidth(geometry.getExtent()), getHeight(geometry.getExtent())) / 3;
    }
    return {
      center,
      coordinates,
      minRadius,
      sqDistances
    };
  }

  handleTranslateEnd = (e: $TSFixMe) => {
    e.features.forEach((feature: $TSFixMe) => {
      const geom = feature.getGeometry();

      // handling out_of_extent case
      const is_out_of_extent = this.mapObj.isGeometryOutOfLotBoundary({
        geom,
        boundary: this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
      });

      if (is_out_of_extent) {
        this.lastFeatures.forEach((lastFeature: $TSFixMe) => {
          if (lastFeature.get('layerId') === feature.get('layerId') && lastFeature.get('id') === feature.get('id')) {
            feature.setGeometry(lastFeature.getGeometry());
          }
        });
      } else {
        const layerId = feature.get('layerId');
        // Push layer in tracker
        layerTracker.push(this.mapObj.getLayerName(layerId), layerId);
      }
    });
    if (layerTracker.getArray().length) {
      this.notifyObservers(TOOL_EVENT.MOVE_FEATURE);
    }
  };

  changeCursor = (e: $TSFixMe) => {
    this.invalidSpace = !this.mapObj.coordsExistsInParcel(
      e.coordinate,
      this.mapObj.isBlueprintMap ? this.bpLotExtent : this.lotFeature
    );
    changeMapCursor(this.invalidSpace, 'not-allowed');
    this.mapObj.map.forEachFeatureAtPixel(e.pixel, (_feature: $TSFixMe, _layer: $TSFixMe) => {
      if (
        (!selectNonHighLightLayers(_layer?.get('name')) &&
          _feature.getGeometry().getType() !== GEOMETRY_TYPE_STRING.POINT) ||
        isNumericalLayer(_layer)
      ) {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        document.getElementById('map').style.cursor = 'pointer';
        return true;
      }
      return false;
    });
  };

  off() {
    this.mapObj.map.un('pointermove', this.changeCursor);
    this.mapObj.map.un('contextmenu', this.removePointOnRightClick);
    if (this.select) {
      this.select.getFeatures().clear();
      this.mapObj.map.removeInteraction(this.select);
    }
    if (this.hover) {
      this.mapObj.map.removeInteraction(this.hover);
    }
    if (this.resizeRotate) {
      this.mapObj.map.removeInteraction(this.resizeRotate);
    }
    if (this.modify) {
      this.mapObj.map.removeInteraction(this.modify);
    }

    if (this.translate) {
      this.mapObj.map.removeInteraction(this.translate);
      this.translate.un('translateend', this.handleTranslateEnd);
    }
    this.lotFeature = null;
    this.bpLotExtent = null;
    this.lastFeatures = [];
    this.areaStyleCache = [];
  }
}

export default ResizeRotate;
