import create from 'zustand';
import { devtools } from 'zustand/middleware';

const TOOL_DATA = {
  edgeSnapping: false,
  vertexSnapping: false,
  isOutputParcelEdit: false
};

const reducer = (state: any, { type, payload }: any) => {
  switch (type) {
    case 'SET_TOOL_SNAPPING':
      return { ...state, [payload.type]: payload.value };
    case 'SET_IS_PARCEL_OUTPUT_EDIT':
      return { ...state, isOutputParcelEdit: payload };
    default:
      return state;
  }
};

const store = (set: any) => ({
  ...TOOL_DATA,
  dispatch: (args: any) => set((state: any) => reducer(state, args))
});

export const useToolData = create(devtools(store));
