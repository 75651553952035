import cloneDeep from 'lodash/cloneDeep';

import LineString from 'ol/geom/LineString';
import { polygon } from '@turf/helpers';
import { asArray } from 'ol/color';
import { getArea, getLength } from 'ol/sphere';
import { Circle as CircleStyle, Fill, Icon, Stroke, Style, Text } from 'ol/style';

import { mapObj } from '../MainPage/OlMap/MapInit';
import { roundNum } from './pureHelpers';
import {
  calculateBPArea,
  calculateBPLength,
  formatCommaNumber,
  getOutputData,
  hexToRgbA,
  isNumericalLayer
} from './HelperFunctions';
import {
  FILL_PATTERNS_ENUM,
  GEOMETRY_TYPE_STRING,
  MAP_LAYERS,
  MEASUREMENT_SYSTEM_ENUM,
  POINT_SHAPES,
  POINT_SHAPES_ENUM,
  TOOLS_ID,
  TYPICAL_ICON
} from '../Constants/Constant';
import { useRequest } from '../Stores/Request';
import { isGeomOrFeatureVisible, mergeFeatAndGeomTags } from '../Components/tags/helpers';

/**
 * @name isValidPolygon
 * @function
 * @description check whether the given polygon has valid geometry
 * @param {Feature|TurfFeature|Coordinates} feature feature
 * @throws {Error} if throws true
 * @returns {boolean} true/false
 */
export function isValidPolygon(feature: $TSFixMe, throws = true) {
  const coords = feature?.length ? feature : getGeomCoords(feature);
  try {
    polygon(coords); // throw error if invalid polygon
  } catch (err) {
    if (throws) throw err;
    return false;
  }
  return true;
}

function getGeomCoords(feature: $TSFixMe) {
  if (typeof feature?.getGeometry === 'function') return feature.getGeometry().getCoordinates();

  return feature?.geometry?.coordinates;
}

/**
 * @name addPixelInCoords
 * @function
 * @description Use to reverse a Object
 * @param {Map} map Map Object
 * @param {Coordinates} coords Coords
 * @param {number} x add pixels to left
 * @param {number} y add pixels to top
 */
export const addPixelInCoords = function addPixelInCoords(map: $TSFixMe, coords: $TSFixMe, x: $TSFixMe, y: $TSFixMe) {
  const pixels = map.getPixelFromCoordinate(coords);
  return map.getCoordinateFromPixel([pixels[0] + parseInt(x, 10), pixels[1] - parseInt(y, 10)]);
};

export const getDiffInPixelsFromCoords = function getDiffInPixelsFromCoords(
  map: $TSFixMe,
  startCoords: $TSFixMe,
  lastCoords: $TSFixMe
) {
  const startPixels = map.getPixelFromCoordinate(startCoords);
  const lastPixels = map.getPixelFromCoordinate(lastCoords);
  return [
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    parseInt(Math.abs(startPixels[0] - lastPixels[0]), 10),
    // @ts-expect-error TS(2345): Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    parseInt(Math.abs(startPixels[1] - lastPixels[1]), 10)
  ];
};

export const filterBlacklistPropertiesInFeatures = (features: $TSFixMe, blacklist: $TSFixMe) => {
  if (blacklist?.length) {
    const newFeatures: $TSFixMe = [];
    features.forEach((f: $TSFixMe, i: $TSFixMe) => {
      newFeatures[i] = f.clone();
      newFeatures[i].setProperties(
        blacklist.reduce(
          // @ts-expect-error TS(7006): Parameter 'acc' implicitly has an 'any' type.
          (acc, val) => ({
            ...acc,
            [val]: undefined
          }),
          {}
        ),
        true
      );
    });
    return newFeatures;
  }
  return features;
};

const labelLengthStyle = new Style({
  text: new Text({
    font: '14px Calibri,sans-serif',
    placement: 'line',
    offsetY: 20,
    fill: new Fill({
      color: 'rgba(0, 0, 0, 1)'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 1)',
      width: 3
    })
  })
});
const labelAreaStyle = new Style({
  text: new Text({
    font: 'bold 16px Calibri,sans-serif',
    fill: new Fill({
      color: 'rgba(0, 0, 0, 1)'
    }),
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 1)',
      width: 3
    })
  })
});

const formatLength = (line: $TSFixMe, mapInfo: $TSFixMe, isImperialSystem: $TSFixMe) => {
  let length = getLength(line);

  if (mapInfo.isBlueprintMap) {
    const baseLayerProps = mapObj.baseLayer?.getProperties() || {};

    const { bp_page_dpi: dpi, bp_page_scale: scale } = baseLayerProps;
    if (!scale) return '';
    length = calculateBPLength({ dpi, scale, length });

    if (isImperialSystem) return `${roundNum(length * 3.281, 1)} ft `;
    return `${roundNum(length, 1)} m`;
  }

  if (isImperialSystem) return `${formatCommaNumber(Math.ceil(length * 3.281))} ft`;
  return `${formatCommaNumber(Math.ceil(length))} m`;
};

const formatArea = (_polygon: $TSFixMe, mapInfo: $TSFixMe, isImperialSystem: $TSFixMe) => {
  let area = getArea(_polygon);

  if (mapInfo.isBlueprintMap) {
    const baseLayerProps = mapObj.baseLayer?.getProperties() || {};

    const { bp_page_dpi: dpi, bp_page_scale: scale } = baseLayerProps;
    if (!scale) return '';
    area = calculateBPArea({ dpi, scale, area });
    if (isImperialSystem) return `${roundNum(area * 10.764, 1)} sq ft`;
    return `${roundNum(area, 1)} sq m`;
  }

  if (isImperialSystem) return `${formatCommaNumber(Math.ceil(area * 10.764))} sq ft`;
  return `${formatCommaNumber(Math.ceil(area))} sq m`;
};

export const getAreaInAcres = (_polygon: $TSFixMe, isImperialSystem: $TSFixMe) => {
  if (!_polygon) return null;
  const area = getArea(_polygon);
  let areaInAcres;
  if (isImperialSystem) {
    const areaInSqFt = roundNum(area * 10.764, 2);
    areaInAcres = areaInSqFt / 43560;
  } else {
    areaInAcres = area / 4047;
  }
  return areaInAcres;
};

export const getAreaStyleFunction = ({
  feature,
  areaLabelStyleCache = [],
  labelStyleCache = [],
  mapInfo = {},
  style = [],
  options = {}
}: $TSFixMe) => {
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  const isImperialSystem = getOutputData()?.measurement_system === MEASUREMENT_SYSTEM_ENUM.IMPERIAL;

  const styles = style;
  const geometry = feature.getGeometry();
  const type = geometry.getType();
  let lineString;
  const isCircle = feature.getProperties()?.isCircle;

  if (options.measureArea && type === 'Polygon') {
    lineString = new LineString(geometry.getCoordinates()[0]);
    let label = formatArea(geometry, mapInfo, isImperialSystem);
    if (!areaLabelStyleCache[0]) areaLabelStyleCache.push(labelAreaStyle.clone());
    areaLabelStyleCache[0].setGeometry(geometry);
    areaLabelStyleCache[0].getText().setText(label);
    styles.push(areaLabelStyleCache[0]);

    if (isCircle) {
      label = formatLength(geometry, mapInfo, isImperialSystem);
      if (!areaLabelStyleCache[1]) areaLabelStyleCache.push(labelLengthStyle.clone());
      areaLabelStyleCache[1].setGeometry(geometry);
      areaLabelStyleCache[1].getText().setText(label);
      areaLabelStyleCache[1].getText().setPlacement('point');
      styles.push(...areaLabelStyleCache);
    }
  } else if (type === 'LineString') {
    lineString = geometry;
  }

  if (options.measureLength && lineString && !isCircle && type === 'LineString') {
    let count = 0;
    lineString.forEachSegment((a: $TSFixMe, b: $TSFixMe) => {
      const segment = new LineString([a, b]);
      const label = formatLength(segment, mapInfo, isImperialSystem);
      if (labelStyleCache.length - 1 < count) {
        labelStyleCache.push(labelLengthStyle.clone());
      }
      labelStyleCache[count].setGeometry(segment);
      labelStyleCache[count].getText().setText(label);
      styles.push(labelStyleCache[count]);
      count++;
    });
  }
  return styles;
};

export const hasMultiFeatures = (layer: $TSFixMe) => {
  return layer?.getSource()?.getFeatures()?.length > 1;
};

export const getWithoutPointSelectedFeatures = (selectedFeatures: $TSFixMe) =>
  selectedFeatures.filter((f: $TSFixMe) => f.getGeometry().getType() !== GEOMETRY_TYPE_STRING.POINT);

export const applyLayerStyleToFeature = ({ layer, feature }: $TSFixMe) => {
  if (layer?.get('name') === MAP_LAYERS.ARROW) {
    feature.setStyle(layer?.get('layerData')?.style({ feature }));
    return null;
  }

  const style = layer?.get('layerData')?.style;
  const { color, opacity, width, pattern = null } = style || {};
  const fillColor = cloneDeep(asArray(color));

  fillColor.splice(3, 1, opacity);
  const geomType = feature?.getGeometry()?.getType();
  let fillStyle = null;
  if (geomType !== GEOMETRY_TYPE_STRING.POINT && pattern && pattern !== FILL_PATTERNS_ENUM.NO_PATTERN) {
    const fillPattern = createPattern(color, opacity, pattern);
    fillStyle = new Fill({ color: fillPattern });
  } else {
    fillStyle = new Fill({
      color: fillColor
    });
  }

  const strokeStyle = new Stroke({
    color,
    width
  });

  const imageStyle = isNumericalLayer(layer)
    ? new Icon({
        color,
        src: 'https://storage.googleapis.com/falcon-shared-images-front-end/assets/svgs/sharp.svg',
        crossOrigin: 'anonymous'
      })
    : geomType === GEOMETRY_TYPE_STRING.POINT && pattern && pattern !== POINT_SHAPES_ENUM.NO_PATTERN
    ? new Icon({
        src: POINT_SHAPES[pattern].image,
        color,
        scale: width / 12,
        crossOrigin: 'anonymous'
      })
    : geomType === GEOMETRY_TYPE_STRING.TYPICAL_UNIT
    ? new Icon({
        src: TYPICAL_ICON.image,
        color,
        scale: width / 12,
        crossOrigin: 'anonymous'
      })
    : new CircleStyle({
        fill: fillStyle,
        stroke: strokeStyle,
        radius: width
      });

  feature.setStyle([
    new Style({
      stroke: strokeStyle,
      fill: fillStyle,
      image: imageStyle
    })
  ]);
  return null;
};

export const applySelectStyleToFeature = (feature: $TSFixMe) => {
  const styles = {};
  const white = [255, 255, 255, 1];
  const blue = [0, 153, 255, 1];
  const width = 3;
  const fill = new Fill({
    color: 'rgba(255,255,255,0.5)'
  });
  const stroke = new Stroke({
    color: blue,
    width
  });
  // @ts-expect-error TS(2339): Property 'Polygon' does not exist on type '{}'.
  styles.Polygon = [
    new Style({
      stroke: new Stroke({
        color: white,
        width: width + 2
      }),
      zIndex: Infinity
    }),
    new Style({
      image: new CircleStyle({
        fill,
        stroke,
        radius: 5
      }),
      fill,
      stroke,
      zIndex: Infinity
    })
  ];
  // @ts-expect-error TS(2339): Property 'MultiPolygon' does not exist on type '{}... Remove this comment to see the full error message
  styles.MultiPolygon = styles.Polygon;
  // @ts-expect-error TS(2339): Property 'LineString' does not exist on type '{}'.
  styles.LineString = [
    new Style({
      stroke: new Stroke({
        color: white,
        width: width + 2
      })
    }),
    new Style({
      stroke
    })
  ];
  // @ts-expect-error TS(2339): Property 'MultiLineString' does not exist on type ... Remove this comment to see the full error message
  styles.MultiLineString = styles.LineString;

  // @ts-expect-error TS(2339): Property 'Circle' does not exist on type '{}'.
  styles.Circle = styles.Polygon.concat(styles.LineString);

  // @ts-expect-error TS(2339): Property 'Point' does not exist on type '{}'.
  styles.Point = [
    new Style({
      image: new CircleStyle({
        radius: width * 2,
        fill: new Fill({
          color: blue
        }),
        stroke: new Stroke({
          color: white,
          width: width / 2
        })
      }),
      zIndex: Infinity
    })
  ];
  // @ts-expect-error TS(2339): Property 'MultiPoint' does not exist on type '{}'.
  styles.MultiPoint = styles.Point;
  // @ts-expect-error TS(2339): Property 'GeometryCollection' does not exist on ty... Remove this comment to see the full error message
  styles.GeometryCollection = styles.Polygon.concat(styles.LineString, styles.Point);
  const geomType = feature.getGeometry().getType();
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  feature.setStyle(styles[geomType]);
};

const Spline = function Spline(this: $TSFixMe, options: $TSFixMe) {
  this.points = options.points || [];
  this.duration = options.duration || 10000;
  this.sharpness = options.sharpness || 0.85;
  this.centers = [];
  this.controls = [];
  this.stepLength = options.stepLength || 500;
  this.length = this.points.length;
  this.delay = 0;
  // this is to ensure compatibility with the 2d version
  for (let i = 0; i < this.length; i++) {
    this.points[i].z = this.points[i].z || 0;
  }
  for (let i = 0; i < this.length - 1; i++) {
    const p1 = this.points[i];
    const p2 = this.points[i + 1];
    this.centers.push({
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
      z: (p1.z + p2.z) / 2
    });
  }
  this.controls.push([this.points[0], this.points[0]]);
  for (let i = 0; i < this.centers.length - 1; i++) {
    const dx = this.points[i + 1].x - (this.centers[i].x + this.centers[i + 1].x) / 2;
    const dy = this.points[i + 1].y - (this.centers[i].y + this.centers[i + 1].y) / 2;
    const dz = this.points[i + 1].z - (this.centers[i].y + this.centers[i + 1].z) / 2;
    this.controls.push([
      {
        x: (1.0 - this.sharpness) * this.points[i + 1].x + this.sharpness * (this.centers[i].x + dx),
        y: (1.0 - this.sharpness) * this.points[i + 1].y + this.sharpness * (this.centers[i].y + dy),
        z: (1.0 - this.sharpness) * this.points[i + 1].z + this.sharpness * (this.centers[i].z + dz)
      },
      {
        x: (1.0 - this.sharpness) * this.points[i + 1].x + this.sharpness * (this.centers[i + 1].x + dx),
        y: (1.0 - this.sharpness) * this.points[i + 1].y + this.sharpness * (this.centers[i + 1].y + dy),
        z: (1.0 - this.sharpness) * this.points[i + 1].z + this.sharpness * (this.centers[i + 1].z + dz)
      }
    ]);
  }
  this.controls.push([this.points[this.length - 1], this.points[this.length - 1]]);
  this.steps = this.cacheSteps(this.stepLength);
  return this;
};

Spline.prototype.cacheSteps = function cacheSteps(mindist: $TSFixMe) {
  const steps = [];
  let laststep = this.pos(0);
  steps.push(0);
  for (let t = 0; t < this.duration; t += 10) {
    const step = this.pos(t);
    const dist = Math.sqrt(
      (step.x - laststep.x) * (step.x - laststep.x) +
        (step.y - laststep.y) * (step.y - laststep.y) +
        (step.z - laststep.z) * (step.z - laststep.z)
    );
    if (dist > mindist) {
      steps.push(t);
      laststep = step;
    }
  }
  return steps;
};

Spline.prototype.pos = function pos(time: $TSFixMe) {
  function bezierInline(_t: $TSFixMe, p1: $TSFixMe, c1: $TSFixMe, c2: $TSFixMe, p2: $TSFixMe) {
    const B = function B(t: $TSFixMe) {
      const t2 = t * t;
      const t3 = t2 * t;
      return [t3, 3 * t2 * (1 - t), 3 * t * (1 - t) * (1 - t), (1 - t) * (1 - t) * (1 - t)];
    };
    const b = B(_t);
    const position = {
      x: p2.x * b[0] + c2.x * b[1] + c1.x * b[2] + p1.x * b[3],
      y: p2.y * b[0] + c2.y * b[1] + c1.y * b[2] + p1.y * b[3],
      z: p2.z * b[0] + c2.z * b[1] + c1.z * b[2] + p1.z * b[3]
    };
    return position;
  }
  let t = time - this.delay;
  if (t < 0) t = 0;
  if (t > this.duration) t = this.duration - 1;
  //t = t-this.delay;
  const t2 = t / this.duration;
  if (t2 >= 1) return this.points[this.length - 1];

  const n = Math.floor((this.points.length - 1) * t2);
  const t1 = (this.length - 1) * t2 - n;
  return bezierInline(t1, this.points[n], this.controls[n][1], this.controls[n + 1][0], this.points[n + 1]);
};

export const getNewLayerWidth = ({ width, geomType, isBlueprintMap, resolution }: $TSFixMe) => {
  // New Width (13% of original width/resolution) in Aerial
  // New Width (500% of original width/resolution) in Bpt
  // max width cap is 15(in case of line) and 30(in case of point)

  // width = (isBlueprintMap ? 5 * width : 0.13 * width) / resolution;
  // if (geomType === GEOMETRY_TYPE_ENUM.POINT) {
  //     return width < 1 ? 1 : width > 30 ? 30 : width;
  // }
  // return width < 1 ? 1 : width > 15 ? 15 : width;
  return width;
};

export function bezier(lineCoordinates: $TSFixMe, paramsOptions: $TSFixMe) {
  // Optional params
  const options = paramsOptions || {};
  const resolution = options.resolution || 10000;
  const sharpness = options.sharpness || 0.85;

  const coords = [];
  // @ts-expect-error TS(7009): 'new' expression, whose target lacks a construct s... Remove this comment to see the full error message
  const spline = new Spline({
    points: lineCoordinates.map((pt: $TSFixMe) => {
      return { x: pt[0], y: pt[1] };
    }),
    duration: resolution,
    sharpness
  });

  for (let i = 0; i < spline.duration; i += 10) {
    const pos = spline.pos(i);
    if (Math.floor(i / 100) % 2 === 0) {
      coords.push([pos.x, pos.y]);
    }
  }

  return coords;
}

export const lightStroke = new Style({
  stroke: new Stroke({
    color: [255, 255, 255],
    width: 2,
    lineDash: [4, 8],
    lineDashOffset: 6
  }),
  fill: new Fill({
    color: [255, 255, 255, 0.3]
  }),
  image: new CircleStyle({
    radius: 5,
    stroke: new Stroke({
      color: '#ffffff'
    }),
    fill: new Fill({
      color: '#4361ee',
      // @ts-expect-error TS(2345): Argument of type '{ color: string; width: number; ... Remove this comment to see the full error message
      width: 1
    })
  })
});

export const darkStroke = new Style({
  stroke: new Stroke({
    color: [0, 0, 0],
    width: 2,
    lineDash: [4, 8]
  })
});

export const createPattern = (color: $TSFixMe, opacity: $TSFixMe, patternEnum: $TSFixMe) => {
  const patternCanvas: any = document.createElement('canvas');
  const patternContext: any = patternCanvas.getContext('2d', { willReadFrequently: true });

  patternCanvas.width = 1920;
  patternCanvas.height = 1080;

  // Give the pattern a background color
  patternContext.fillStyle = hexToRgbA(color, opacity);
  // Clear the canvas
  patternContext.fillRect(0, 0, patternCanvas.width, patternCanvas.height);

  let numRows;
  let numCols;
  let squareSize;
  let gridSize;

  switch (patternEnum) {
    case FILL_PATTERNS_ENUM.DIAMOND: {
      const boxWidth = patternCanvas.width / 150;
      const boxHeight = boxWidth * 1.32;

      patternContext.fillStyle = 'black';
      patternContext.strokeStyle = 'black';
      patternContext.lineWidth = 1;

      for (let i = 0; i < 150; i++) {
        for (let j = 0; j < 150; j++) {
          const x = i * boxWidth;
          const y = j * boxHeight;

          patternContext.beginPath();
          patternContext.moveTo(x + boxWidth / 2, y);
          patternContext.lineTo(x + boxWidth, y + boxHeight / 2);
          patternContext.lineTo(x + boxWidth / 2, y + boxHeight);
          patternContext.lineTo(x, y + boxHeight / 2);
          patternContext.closePath();
          patternContext.stroke();
          patternContext.fill();
        }
      }
      break;
    }
    case FILL_PATTERNS_ENUM.ZIGZAG: {
      patternContext.lineWidth = 5;
      patternContext.strokeStyle = 'black';

      let startX = 0;
      let startY = 0;
      let positionY = 0;
      let extraLinesX = 0;
      let extraLinesY = 0;
      const spaceBetweenLines = 20;
      const zigzagSpacing = 50;

      for (let i = 0; i < 100; i++) {
        positionY = i * spaceBetweenLines;
        startX = 0;
        startY = i * spaceBetweenLines;

        for (let j = 0; j < 40; j++) {
          if (i === 0) {
            if (j % 2 === 0) {
              extraLinesX = startX + spaceBetweenLines;
              extraLinesY = startY;
            } else {
              extraLinesX = startX;
              extraLinesY = startY - spaceBetweenLines;
            }

            patternContext.beginPath();
            patternContext.moveTo(extraLinesX, extraLinesY);

            if (j % 2 === 0) {
              extraLinesX = startX + zigzagSpacing;
              extraLinesY = zigzagSpacing - spaceBetweenLines;
            } else {
              extraLinesX = startX + zigzagSpacing - spaceBetweenLines;
              extraLinesY = 0;
            }

            patternContext.lineTo(extraLinesX, extraLinesY);
            patternContext.closePath();
            patternContext.stroke();

            if (j % 2 === 0) {
              extraLinesX = startX + 2 * spaceBetweenLines;
              extraLinesY = startY;
            } else {
              extraLinesX = startX;
              extraLinesY = startY - 2 * spaceBetweenLines;
            }

            patternContext.beginPath();
            patternContext.moveTo(extraLinesX, extraLinesY);

            if (j % 2 === 0) {
              extraLinesX = startX + zigzagSpacing;
              extraLinesY = zigzagSpacing - 2 * spaceBetweenLines;
            } else {
              extraLinesX = startX + zigzagSpacing - 2 * spaceBetweenLines;
              extraLinesY = 0;
            }

            patternContext.lineTo(extraLinesX, extraLinesY);
            patternContext.closePath();
            patternContext.stroke();
          }
          patternContext.beginPath();
          patternContext.moveTo(startX, startY);

          const x = startX + zigzagSpacing;
          let y;

          if (j % 2 === 0) {
            y = positionY + zigzagSpacing;
          } else {
            y = positionY;
          }

          patternContext.lineTo(x, y);
          patternContext.closePath();
          patternContext.stroke();
          startX = x;
          startY = y;
        }
      }
      break;
    }
    case FILL_PATTERNS_ENUM.HONEYCOMB: {
      const hexRadius = 10;
      const hexWidth = Math.sqrt(3) * hexRadius;
      const hexHeight = 1.8 * hexRadius;
      numRows = 150;
      numCols = 150;

      patternContext.strokeStyle = 'black';
      patternContext.lineWidth = 1;

      for (let row = 0; row < numRows; row++) {
        for (let col = 0; col < numCols; col++) {
          const x = col * hexWidth + (row % 2 === 1 ? hexWidth / 2 : 0);
          const y = row * ((hexHeight * 3) / 4);

          patternContext.beginPath();
          patternContext.moveTo(x + hexWidth / 2, y);
          patternContext.lineTo(x + hexWidth, y + hexHeight / 4);
          patternContext.lineTo(x + hexWidth, y + (3 * hexHeight) / 4);
          patternContext.lineTo(x + hexWidth / 2, y + hexHeight);
          patternContext.lineTo(x, y + (3 * hexHeight) / 4);
          patternContext.lineTo(x, y + hexHeight / 4);
          patternContext.closePath();
          patternContext.stroke();
        }
      }
      break;
    }
    case FILL_PATTERNS_ENUM.CHESS: {
      squareSize = 15;
      numCols = 150;
      numRows = 150;

      for (let row = 0; row < numRows; row++) {
        for (let col = 0; col < numCols; col++) {
          const isBlack = (row + col) % 2 === 1;

          patternContext.fillStyle = isBlack ? 'black' : hexToRgbA(color, opacity);
          patternContext.fillRect(col * squareSize, row * squareSize, squareSize, squareSize);
        }
      }
      break;
    }
    case FILL_PATTERNS_ENUM.GRID: {
      gridSize = 14.4;
      numRows = patternCanvas.height / gridSize;
      numCols = patternCanvas.width / gridSize;

      patternContext.strokeStyle = 'black';
      patternContext.lineWidth = 2;

      // Draw horizontal lines
      for (let row = 1; row < numRows + 1; row++) {
        const y = row * gridSize;
        patternContext.beginPath();
        patternContext.moveTo(0, y);
        patternContext.lineTo(patternCanvas.width, y);
        patternContext.stroke();
      }

      // Draw vertical lines
      for (let col = 1; col < numCols; col++) {
        const x = col * gridSize;
        patternContext.beginPath();
        patternContext.moveTo(x, 0);
        patternContext.lineTo(x, patternCanvas.height);
        patternContext.stroke();
      }
      break;
    }
    case FILL_PATTERNS_ENUM.DOTS: {
      gridSize = 14.2;
      numRows = patternCanvas.height / gridSize;
      numCols = patternCanvas.width / gridSize;
      const circleRadius = 2;

      patternContext.fillStyle = 'black';

      for (let row = 0; row < numRows; row++) {
        for (let col = 0; col < numCols; col++) {
          const x = col * gridSize;
          const y = row * gridSize;

          patternContext.beginPath();
          patternContext.arc(x, y, circleRadius, 0, 2 * Math.PI);
          patternContext.fill();
        }
      }
      break;
    }
    case FILL_PATTERNS_ENUM.DIAGONAL_LINE: {
      const lineSpacing = 20;
      patternContext.strokeStyle = 'black';
      patternContext.lineWidth = 3;

      for (let i = 0; i < patternCanvas.width + patternCanvas.height; i += lineSpacing) {
        patternContext.beginPath();
        patternContext.moveTo(0, i);
        patternContext.lineTo(i, 0);
        patternContext.stroke();
      }
      break;
    }
    default:
      patternContext.fillStyle = 'black';
      patternContext.beginPath();
      patternContext.arc(10, 10, 4, 0, 2 * Math.PI);
      patternContext.fill();
  }

  // Create our primary canvas and fill it with the pattern
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  // @ts-expect-error TS(2531): Object is possibly 'null'.
  const pattern = ctx.createPattern(patternCanvas, null);

  return pattern;
};

const applyCutAndCopyStyle = ({ feature, faded = false }: $TSFixMe) => {
  feature.setStyle(
    new Style({
      stroke: new Stroke({
        color: [67, 97, 238, faded ? 0.5 : 1],
        width: 2
      }),
      fill: new Fill({
        color: [67, 97, 238, faded ? 0.2 : 0.3]
      }),
      image: new CircleStyle({
        radius: 5,
        stroke: new Stroke({
          color: '#ffffff'
        }),
        fill: new Fill({
          color: [67, 97, 238, faded ? 0.5 : 1],
          // @ts-expect-error TS(2345): Argument of type '{ color: number[]; width: number... Remove this comment to see the full error message
          width: 1
        })
      })
    })
  );
};

export const getClipboardPayload = ({ features, toolId, changeStyle = false }: $TSFixMe) => {
  const payload = Object.create({ isCopied: toolId === TOOLS_ID.COPY_TOOL });

  features.forEach((feature: $TSFixMe) => {
    const layerId = feature.get('layerId');
    if (payload[layerId]) {
      payload[layerId].push(feature);
    } else {
      payload[layerId] = [feature];
    }

    changeStyle && applyCutAndCopyStyle({ feature, faded: toolId === TOOLS_ID.CUT_TOOL });
  });
  return payload;
};

export function getTableData(outputs: $TSFixMe, geomType: $TSFixMe, isAerialData: boolean = false) {
  function getArrayIndexByName(array: $TSFixMe, name: $TSFixMe) {
    return array.findIndex((v: $TSFixMe) => v.name === name);
  }

  const data = [];
  const tagQuery = useRequest.getState()?.tagQuery;

  for (let i = 0; i < outputs.length; i++) {
    const output = outputs[i];
    if (output.feature?.geometry_type === geomType) {
      // area (sqft) and parameter (ft)
      const measurements = output.measurements || {};

      const dict: any = {};
      dict.id = output.id; // id (it's different than feature.id)
      dict.feature = output.feature; // { id (In case of blueprint it is undefined), name, geometry_type, is_custom }
      dict.style = { ...output.style, color: output.style?.color || '#000' }; // { color, width, opacity, is_visible, layer_order }
      if (output?.group?.id) {
        dict.group_id = output?.group?.id;
      }
      if (output?.group?.name) {
        dict.group_name = output?.group?.name;
      }
      if (output?.assemblies) {
        dict.assemblies = output.assemblies;
      }
      if (output?.presentOnSheets) {
        dict.presentOnSheets = output.presentOnSheets;
      }
      if (output?.attributes) {
        dict.attributes = output.attributes;
      }
      if (output?.attribute_based_measurements) {
        dict.attribute_based_measurements = output.attribute_based_measurements;
      }

      const areaIndex = getArrayIndexByName(measurements, 'area');
      const perimeterIndex = getArrayIndexByName(measurements, 'perimeter');
      const lengthIndex = getArrayIndexByName(measurements, 'length');
      const countIndex = getArrayIndexByName(measurements, 'count');
      const volumneIndex = getArrayIndexByName(measurements, 'volume');
      const weightIndex = getArrayIndexByName(measurements, 'weight');
      const weightTonIndex = getArrayIndexByName(measurements, 'weight_ton');

      if (measurements[areaIndex]) {
        dict.area = measurements[areaIndex].value || 0; // area in sqft
      }
      if (measurements[perimeterIndex]) {
        dict.perimeter = measurements[perimeterIndex].value || 0; // parameter in ft
      }
      if (measurements[lengthIndex]) {
        dict.length = measurements[lengthIndex].value || 0;
      }
      if (measurements[countIndex]) {
        dict.count = measurements[countIndex].value || 0;
      }
      if (measurements[volumneIndex]) {
        dict.volume = measurements[volumneIndex].value || 0;
      }
      if (measurements[weightIndex]) {
        dict.weight = measurements[weightIndex].value || 0;
      }
      if (measurements[weightTonIndex]) {
        dict.weight_ton = measurements[weightTonIndex].value || 0;
      }

      const childData = [];

      // convert geoJson features data into an object with below keys
      const features = output?.output_geojson?.features || [];
      const featureTags = output?.feature?.default_tags || {};
      for (let j = 0; j < features.length; j++) {
        const feature = features[j];
        const geomTags = feature?.properties?.tags_info || {};
        const resOnTagLevel = isGeomOrFeatureVisible(geomTags, tagQuery);

        // @ts-expect-error
        const mergedTags = mergeFeatAndGeomTags(featureTags, [geomTags]);

        const resOnBothLevel = isGeomOrFeatureVisible(mergedTags, tagQuery);
        const res = resOnTagLevel || resOnBothLevel;

        if (!res) {
          continue;
        }

        const childDict: any = {};
        childDict.id = feature.properties.id;
        childDict.area = feature.properties.area;
        childDict.edit_area = feature.properties.edit_area;
        childDict.edit_perimeter = feature.properties.edit_perimeter;
        childDict.edit_length = feature.properties.edit_length;
        childDict.perimeter = feature.properties.perimeter;
        childDict.length = feature.properties.length;
        childDict.volume = feature.properties.volume;
        childDict.weight = feature.properties.weight;
        childDict.weight_ton = feature.properties.weight_ton;
        childDict.count = Number.isNaN(Number(feature.properties.count)) ? 1 : feature.properties.count;
        childDict.parentId = output.id;
        childDict.uid = `${output.id}-${feature.properties.id}`;
        if (feature.properties.id) {
          childData.push(childDict);
        }
      }
      dict.features = childData;
      data.push(dict);
    }
  }

  if (isAerialData) return data;
  return data.sort((a, b) => a?.feature?.name.localeCompare(b?.feature?.name));
}
